/**
 * Created by mvouve on 4/29/2017.
 */

(function($) {
    var divs = $('.breadcrumb-bar__newsflash-link');

    function rotate() {
        var current = divs.first();

        setInterval(function () {
            current.fadeOut({
                // wait for animation to complete before begining fade in.
                complete: function() {
                    if (current.is(divs.last())) {
                        current = divs.first();
                    } else {
                        current = current.next();
                    }
                    current.fadeIn();
                }
            });
        }, 5000);
    }
    if(divs.size() > 1) {
        rotate();
    }
}(jQuery));
