jQuery(function($) {
/*	$('.js-carousel-size').each(function(){
		var $carousel = $(this),
			max_w = $carousel.width(),
			max_h = $carousel.height();

		$carousel.find('img').each(function() {
			var $img = $(this), $container = $img.closest('.js-carousel');

			var h = $img.prop('height'), w = $img.prop('width');
			if (h > max_h) {
				h = max_h;
				w = Math.ceil($img.prop('width') / $img.prop('height') * max_h);
			}
			if (w > max_w) {
				h = Math.ceil($img.prop('height') / $img.prop('width') * max_w);
				w = max_w;
			}

			$img.css({
				'display': 'block',
				'margin': '0 auto',
				'height': h + 'px',
				'width': w + 'px'
			});
		});
	}); */
});