(function($) {
	//$(window).resize(onResize());
	//$(window).ready(onResize());
	function onResize(){
		$('.recipe-block__image img').each(function() {
			var h = $(this).width() * 0.9;
			$(this).css({'height':h+'px'});
		});
		$('.recipe-section__post-image img').each(function() {
			var h = $(this).width() * 0.9;
			$(this).css({'height':h+'px'});
		});
	}
}(jQuery));