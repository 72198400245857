jQuery(function($) {
	$('.js-load-more-link').on('click', function(e) {
		e.preventDefault();

		var $t = $(this),
			$next = $t.closest('.js-load-more')
			.find('.load-more--visible:last').next();

		$next.addClass('load-more--visible');
		if(!$next.next().length) {
			$t.hide();
		}
	});
});