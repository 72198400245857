jQuery(function($) {
	$('.wpp-views').each(function(){
		var views = $(this).text().split(" ");
		$(this).text(' ' + views[0]);
	});

	$('.wpp-comments').each(function(){
		var comments = $(this).text().split(" ");
		$(this).text(' ' + comments[0]);
	});
});