(function($) {
	var breakMedium = 479,
		breakLarge = 767;
	
	$(function(){
		$('.front-carousel').slick({
			dots: true,
			autoplay: true
		});
		
		$('.js-latest-features-carousel').slick({
			prevArrow: '.latest-features__previous',
			nextArrow: '.latest-features__next',
			dots: false,
			autoplay: false,
			slidesToShow: 4,
			slidesToScroll: 4,
			responsive: [
				{
					breakpoint: breakLarge,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: breakMedium,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		});
	});
}(jQuery));