(function($) {
	var moveAds = function(newSize) {
		var leaderAd = $('.site-header__adspace');
		//var footerAd = $('#advertisement-15');

		var $placeholders = $('.ad-placer');
		var $body = $('body');
		var $content = $('#inner-content');
		var $elements = [];

		if(newSize == 'small') {
			var content = $('#content');
			/*if (footerAd.length) {
			 $('<div class="footer-placeholder">').insertAfter(footerAd);
			 content.after(footerAd);
			 }*/
			$('<div class="leader-placeholder">').insertAfter(leaderAd);
			leaderAd.insertAfter($('.topbar-links'));
			// finds all the remaining ads within the content tag.
			var $ads = content.find('.widget_advertisement');

			// if the middle banner exists.
			if ($('.news-section').length) {
				var ittr = 0;
				var frontPageElements = [$('.more-links').first(), $('.latest-features').first(), $('.ask-allergist').first()];
				$ads.each(function () {
					// advertisement 15 & 20 hold their places
					if ((!($(this).is('#advertisement-20'))) && (!($(this).is('#advertisement-15'))) && frontPageElements[ittr] !== undefined && frontPageElements[ittr].length) {
						moveAd($(this), frontPageElements[ittr]);
						ittr++;
					}
				});
      } else if($body.hasClass('single-recipe')) {
        if($content.length) {
					$elements = $content.find('.recipe__summary');
          if(!$elements.length) {
            $elements = $content.find('.recipe__hero');
          }
          $elements = $elements.add('.recipe-tip');
          $elements = $elements.add('.recipe__instructions');
          $elements = $elements.add('#sidebar1');
				}
        moveSelectionAds($ads, $elements);
			} else if($body.hasClass('tax-recipe_category') || $body.hasClass('post-type-archive-recipe')) {
				if($content.length) {
					$elements = $content.find('.recipe-block');
				}
				moveArchiveAds($ads, $elements);
			} else if($body.hasClass('archive')) {
				if($content.length) {
					$elements = $content.find('article');
				}
				moveArchiveAds($ads, $elements);
			} else {
				$content = $('.entry-content');
				if($content.length) {
					$elements = $content.find('p');
				} else {
					$elements = $main.children();
				}
				if(!$elements.length) {
					return;
				}
				moveArchiveAds($ads, $elements);
			}
		} else {
			/*if(footerAd.length) {
			 footerAd.insertBefore($('.footer-placeholder'));
			 $('.footer-placeholder').remove();
			 }*/
			leaderAd.insertBefore($('.leader-placeholder'));
			$('.leader-placeholder').remove();
			$placeholders.each(function() {
				var $placeholder = $(this),
					$ad = $('#'+$placeholder.data('ad-placer'));

				// Responsive script will add the iframe when moved.
				// So we need to remove the old iframe first.
				if($ad.find('script[id^=tag]')) {
					$ad.find('iframe').remove();
				}

				$ad.remove().insertBefore($placeholder);
				$placeholder.remove();
			});
		}

	};

	// Do this on load
	$(function() {
		moveAds(Foundation && Foundation.MediaQuery && Foundation.MediaQuery.current);
	});

	// Do this on window size change (e.g. Rotation)
	$(window).on('changed.zf.mediaquery', function(event, newSize, oldSize) {
		moveAds(newSize);
	});

	/**
	 * This function helps to move ads in a somewhat even fashion way between archive boxes.
	 * @param ads
	 * @param elements
	 */
	function moveArchiveAds(ads, elements){
		// add ads after paragraphs.
		var every = (elements.length / ads.length);
		var current = 0;
		ads.each(function() {
			if((!($(this).is('#advertisement-20')))) {
				if(!moveAd($(this), elements[Math.floor(current += every)])) {
					moveAd($(this), elements[elements.length - 1]);
				}
			}
		});
	}

  	/**
	 * This function helps to move ads in a somewhat even fashion way between archive boxes.
	 * @param ads
	 * @param elements
	 */
	function moveSelectionAds(ads, elements){
		// add ads after paragraphs.
    var idx = 0;
		ads.each(function() {
      var $this = $(this);
			if(!$this.is('#advertisement-20')) {
				moveAd($this, elements[Math.min(elements.length - 1, idx)]);
        idx++;
			}
		});
	}

	/**
	 * Takes an ad widget and moves it after an element.
	 *
	 * @param ad the ad widget to move
	 * @param element the element to append the adWidget after.
	 * @returns {boolean} false if no element exists.
	 */
	function moveAd(ad, element) {
		if(!element) {
			return false;
		}

		// Set a placeholder to move the ad back
		$('<div class="ad-placer">').data('ad-placer',ad.attr('id')).insertAfter(ad);

		// Responsive script will add the iframe when moved.
		// So we need to remove the old iframe first.
		element.after(ad.get(0));

		return true;
	}
}(jQuery));